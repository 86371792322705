<template lang="">
  <div class="resumeForm">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <draggable v-model="educations" handle=".handle">
        <b-card v-for="(edu, i) in educations" :key="i" class="mt-3">
          <b-card-header class="clearfix" header-text-variant="primary">
            <b-icon icon="arrows-expand" class="handle mr-2"></b-icon>
            {{ i + 1 }} : {{ edu.school }}
            <b-button
              v-on:click="removeSchool(i)"
              size="sm float-right"
              class="ml-3"
              variant="outline-danger"
              v-b-tooltip.hover
              title="Delete"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
            <b-button-group
              size="sm float-right"
              calss="mr-3"
              v-if="educations.length > 1"
            >
              <!-- Move up -->
              <b-button
                v-on:click="MoveUp(i)"
                v-if="i != 0"
                size="sm float-right"
                variant="outline-dark"
                v-b-tooltip.hover
                title="Move up"
              >
                <b-icon icon="chevron-up" aria-hidden="true"></b-icon>
              </b-button>
              <!-- Move down -->
              <b-button
                v-on:click="MoveDn(i)"
                v-if="i != educations.length - 1"
                size="sm float-right"
                variant="outline-dark"
                calss="ml-1"
                v-b-tooltip.hover
                title="Move down"
              >
                <b-icon icon="chevron-down" aria-hidden="true"></b-icon>
              </b-button>
            </b-button-group>
          </b-card-header>
          <div class="card-inner">
            <!-- -------------------- -->
            <b-form-group
              id="input-group1"
              label="School Name:"
              label-for="'input1"
            >
              <b-form-input
                id="input1"
                v-model="edu.school"
                placeholder="School name"
              ></b-form-input>
            </b-form-group>
            <!-- -------------------- -->
            <b-form-group
              id="input-group2"
              label="Field of study"
              label-for="'input2"
            >
              <b-form-input
                id="input2"
                v-model="edu.field"
                placeholder="Field of study:"
              ></b-form-input>
            </b-form-group>
            <!-- -------------------- -->
            <!-- <b-form-group id="input-group3" label="Degree:" label-for="'input3">
            <b-form-input
              id="input3"
              v-model="edu.degree"
              placeholder="i.e. MS.E"
            ></b-form-input>
          </b-form-group> -->
            <b-form-group id="input-group3" label="Degree:" label-for="input-3">
              <b-form-select
                id="input-3"
                v-model="edu.degree"
                :options="degree_options"
                required
              ></b-form-select>
            </b-form-group>
            <b-form-group
              v-if="edu.degree === 'Other'"
              id="input-group32"
              label="Enter your degree"
              label-for="input-32"
            >
              <b-form-input
                id="input-32"
                v-model="edu.other"
                required
              ></b-form-input>
            </b-form-group>
            <!-- -------------------- -->
            <b-form-group
              id="input-group5"
              label="School location:"
              label-for="'input5"
            >
              <b-form-input
                id="input5"
                v-model="edu.location"
                placeholder="i.e. City, State, Country"
              ></b-form-input>
            </b-form-group>
            <!-- -------------------- -->
            <b-form-group>
              <b-row>
                <!-- -------------------- -->
                <b-col>
                  Start Date:
                  <vue-monthly-picker
                    id="start-date"
                    v-model="edu.start_date"
                    :monthLabels="locale"
                    alignment="left"
                    placeHolder="YYYY/MM"
                    dateFormat="YYYY/MM"
                  >
                  </vue-monthly-picker>
                </b-col>
                <!-- -------------------- -->
                <b-col>
                  Graduation Date:
                  <div :v-if="edu.currently_here != 1">
                    <vue-monthly-picker
                      id="end-date"
                      v-model="edu.end_date"
                      :monthLabels="locale"
                      placeHolder="YYYY/MM"
                      dateFormat="YYYY/MM"
                      :disabled="edu.currently_here == 1"
                    >
                    </vue-monthly-picker>
                  </div>
                  <!-- -------------------- -->
                  <b-form-checkbox
                    :id="'checkbox-' + i"
                    v-model="edu.currently_here"
                    name="checkbox-1"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Curretnly studying here
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>
            <!-- -------------------- -->
            <b-form-group
              id="input-group-6"
              label="Date display option:"
              label-for="input-6"
            >
              <b-form-select
                id="input-6"
                v-model="edu.date_show_option"
                :options="date_display"
                required
              ></b-form-select>
            </b-form-group>
          </div>
        </b-card>
      </draggable>
      <div class="form-group">
        <b-button v-on:click="addSchool" variant="secondary" class="mt-2">
          Add school
        </b-button>
      </div>
      <hr />
      <b-form-group class="text-center" id="buttons">
        <b-button
          center
          v-on:click="submit_form(false, $event)"
          id="bt_save"
          variant="outline-primary"
          class=""
          >Save</b-button
        >
        <b-button
          v-on:click="submit_form(true, $event)"
          id="bt_submit"
          variant="success"
          class="ml-5"
          >Next: Skills &#10132;</b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
import VueMonthlyPicker from "vue-monthly-picker";
import draggable from "vuedraggable";
export default {
  name: "FromEducation",
  data() {
    return {
      show: true,
      locale: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      degree_options: [
        { text: "Select...", value: null },
        { text: "High school diploma", value: "High school diploma" },
        { text: "GED", value: "GED" },
        { text: "Associate of Art", value: "Associate of Art" },
        { text: "Associate of Science", value: "Associate of Science" },
        { text: "Bachelor of Art", value: "Bachelor of Art" },
        { text: "Bachelor of Science", value: "Bachelor of Science" },
        { text: "BBA", value: "BBA" },
        { text: "Master of Arts", value: "Master of Arts" },
        { text: "Master of Science", value: "Master of Science" },
        { text: "MBA", value: "MBA" },
        { text: "MD", value: "MD" },
        { text: "JD", value: "JD" },
        { text: "Ph.D", value: "Ph.D" },
        { text: "Other...", value: "Other" },
      ],
      date_display: [
        { text: "Show start and graduation date", value: 0 },
        { text: "Show graduation date only", value: 1 },
        { text: "Don't show dates", value: 2 },
      ],
      education_template: {
        school: "",
        field: "",
        degree: null,
        location: "",
        start_date: "",
        end_date: "",
        date_show_option: 0,
        currently_here: 0,
      },
    };
  },
  computed: {
    educations: {
      get() {
        return this.$store.state.resume.educations;
      },
      set(val) {
        console.log("set educations");
        let resume = this.$store.state.resume;
        resume.educations = val;
        for (let i = 0; i < resume.educations.length; i++) {
          resume.educations[i].sort = i;
        }
        this.$store.commit("updateResume", resume);
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      },
    },
  },
  components: {
    VueMonthlyPicker,
    draggable,
  },
  //props: ["educations"],
  watch: {
    educations: function () {
      if (this.educations.length == 0) this.addSchool();
    },
  },
  created() {
    if (this.educations.length == 0) this.addSchool();
  },
  methods: {
    /**********************************************************/
    refresh_form() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    /**********************************************************/
    convert_dates() {
      for (let i = 0; i < this.educations.length; i++) {
        if (this.educations[i].start_date) {
          this.educations[i].start_date = this.$options.filters.dateTime(
            this.educations[i].start_date
          );
        }

        if (this.educations[i].end_date) {
          this.educations[i].end_date = this.$options.filters.dateTime(
            this.educations[i].end_date
          );
        }
      }
    },
    /**********************************************************/
    MoveUp(i) {
      var b = this.educations[i];
      this.educations[i] = this.educations[i - 1];
      this.educations[i - 1] = b;
      this.refresh_form();
    },
    /**********************************************************/
    MoveDn(i) {
      var b = this.educations[i];
      this.educations[i] = this.educations[i + 1];
      this.educations[i + 1] = b;
      this.refresh_form();
    },
    /**********************************************************/
    removeSchool(i) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.educations.splice(i, 1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**********************************************************/
    addSchool() {
      let newEdu = {
        ...this.education_template,
      };
      this.educations.push(newEdu);
    },
    /**********************************************************/
    submit_form(redirect, event) {
      event.preventDefault();
      this.convert_dates();
      this.$emit("save", this.educations, redirect);
    },
    /**********************************************************/
    onSubmit(event) {
      event.preventDefault();
    },
    /**********************************************************/
    onReset(event) {
      event.preventDefault();
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
.card-inner {
  padding: 20px;
}
.card-body {
  padding: 0px;
}
.resumeForm {
  text-align: left;
}
</style>
