<template lang="">
  <div>
    <h3>Educations</h3>
    <form-educations
      :educations="this.$store.state.resume.educations"
      @save="save"
    />
  </div>
</template>
<script>
import FromEducation from "../components/forms/FormEducations.vue";
export default {
  components: {
    "form-educations": FromEducation,
  },
  mounted() {
    // let resume = this.$store.state.resume;
    // resume.educations = [...this.educations];
    // this.$store.commit("updateResume", resume);
    this.$api.fetchResumeEducations();
  },

  methods: {
    save(educations, redirect) {
      let resume = this.$store.state.resume;
      resume.educations = educations;
      this.$store.commit("updateResume", resume);
      this.$api.updateResumeEducations(educations);
      this.$api.successToast();

      if (redirect) {
        this.$router.push("skills");
      }
    },
  },
};
</script>
<style lang=""></style>
